export const moduleConst = {
    namespaced: true,
    state: {
      const: {
        manageDomain: process.env.VUE_APP_API_MANAGE_DOMAIN,
        makeDomain: process.env.VUE_APP_API_MAKE_DOMAIN,
        uploadDomain: process.env.VUE_APP_API_UPLOAD_DOMAIN,
        uploadFtpDomain: process.env.VUE_APP_API_UPLOAD_FTP_DOMAIN,
        crawlerDomain: process.env.VUE_APP_API_CRAWLER_DOMAIN,
        celeryFlowerDomain: process.env.VUE_APP_CELERY_FLOWER_DOMAIN,
        headers:  {
              'Content-Type': 'application/json',
              'Accept-Charset': 'utf-8',
              'Accept': 'application/json'
        },
        changeOperate: {
            "新增":"insert" ,
            "修改":"update" ,
            "刪除":"delete" ,
        },
        uploadResultOptions:{
            "upload":[{ text: '未选择', value: null }, '未上传', '成功', '失败', '永久停止上传'],
            "upload_ftp":[{ text: '未选择', value: null }, '未上传', '成功', '失败'],
            "crawler":[{ text: '未选择', value: null }, '未上传', '成功', '失败'],
            "make":[{ text: '未选择', value: null }, '未上传', '物件儲存成功', '物件儲存失败', '成功', '失败'],
        },
        downloadResultOptions:{
            "crawler":[{ text: '未选择', value: null }, '未下载', '成功', '失败', '永久停止下载'],
        }
      },
    },
}