class ResponseData {
     /**
   * @param {Object} data - 返回的数据对象
   * @param {String} message - 描述响应状态的消息
   * @param {Number} code - HTTP 响应状态码
   * @param {Boolean} is_success - 请求是否成功，默认为 true
   */
  constructor(data, message, code, is_success = true) {
    this.data = data;
    this.message = message;
    this.is_success = is_success;
    this.code = code
  }
}

export const moduleAjax = {
  namespaced: true,
  state: {
      responseData: null
  },

  mutations: {
    setResponseData(state, responseData) {
          state.responseData = responseData
        },
  },
  actions: {
    async send({ commit,dispatch }, request) {
        try{
          // console.log("send")
          let response = await fetch(
              request,
              {credentials: 'include'});
            console.log(response);
          if (response.ok) {
              let jsonResponse = await response.json();
              await dispatch("processStatus",jsonResponse);
          }
          else if (response.status === 401) {
              let jsonResponse = await response.json();
              await dispatch("processStatus",jsonResponse);
          }
          else {
                let error = new Error('伺服器錯誤 ' + response.statusText);
                error.response = response;
                throw error;
          }
        }catch (error) {
            console.error('Error fetching data:', error);
            if (error.message=== "Failed to fetch") {
                error.message = "伺服器端錯誤，無法連線"
            }
            let responseData = new ResponseData(
                {},
                error.message,
                error.response?.status || 0,
                false);
            commit('setResponseData', responseData);
        }
    },
    async processStatus({dispatch},response) {
      console.log("processStatus")
      console.log(response)
      if (response.code >= 200 && response.code < 300) {
        console.log("http 200-300")
          await dispatch("successHandling",response)
      } else {
        console.log("非 http 200")
         await dispatch("failHandling",response)
      }
    },

    async successHandling({commit},response) {
      console.log("successHandling")
      let responseData=   new ResponseData(
          response.data,
          JSON.stringify(response.message),
          response.code,
          true)
      commit('setResponseData', responseData);
      },

    async failHandling({commit},response) {
        let message = "預設錯誤訊息"
        if (Object.prototype.hasOwnProperty.call(response, "msg")) {
          console.log("解析msg")
          message = response.msg
        } else if (Object.prototype.hasOwnProperty.call(response, "message")) {
          console.log("解析message")
          message = response.message
        } else if (Object.prototype.hasOwnProperty.call(response, "messages")) {
          console.log("解析messages")
          message = response.messages.json
        } else {
          console.log("解析errors.json ")
          message = response.errors.json
        }
        let responseData=   new ResponseData(
            {},
            message,
            response.code,
            false)
        commit('setResponseData', responseData);
      }
  }
}