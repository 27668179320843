<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
<!--        primary, success, info, warning, danger, dark, light-->
      <b-navbar-brand><router-link to="/">[传图管理后台]</router-link></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>

          <!-- 宝典图库 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
          <b-nav-text class="color-text">宝典图库</b-nav-text>
          <b-nav-item-dropdown text="[传图后台]" right>
              <b-dropdown-item disabled>资料查询</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-query">[传图后台查询]</router-link></b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item disabled>上传相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-query-and-operate">重新将图片上传到[下游网站]</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-query-and-operate">永久停止上传到[下游网站]</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-query-and-operate">清理CDN图片缓存</router-link></b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item disabled>网域相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-replace-api-server-domain">更换[下游网站]网域</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-replace-cdn-domain">更换[图片CDN]网域</router-link></b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="[制图后台]" right>
              <b-dropdown-item disabled>资料查询</b-dropdown-item>
              <b-dropdown-item ><router-link to="/make-query">[制图后台查询]</router-link></b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item disabled>储存相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/make-save-image-single">储存图片-单张</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/make-save-image-multiple">储存图片-多张</router-link></b-dropdown-item>
              <b-dropdown-divider v-if="isDevelopmentOrRoot"/>
              <b-dropdown-item v-if="isDevelopmentOrRoot"  disabled>制图失败相关</b-dropdown-item>
              <b-dropdown-item v-if="isDevelopmentOrRoot"  ><router-link to="/make-failed-msg">制图失败讯息纪录</router-link></b-dropdown-item>
              <b-dropdown-divider v-if="isDevelopmentOrRoot"/>
              <b-dropdown-item v-if="isDevelopmentOrRoot"  disabled>制图相关</b-dropdown-item>
              <b-dropdown-item v-if="isDevelopmentOrRoot" ><router-link to="/make-query-and-operate">重新将图片掃描</router-link></b-dropdown-item>
              <b-dropdown-item v-if="isDevelopmentOrRoot" ><router-link to="/make-query-and-operate">重新将图片上传到[Oss]</router-link></b-dropdown-item>
              <b-dropdown-item v-if="isDevelopmentOrRoot" ><router-link to="/make-query-and-operate">重新将图片上传到[传图后台]</router-link></b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- FTP图库 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
          <b-nav-text class="color-text">FTP图库</b-nav-text>
          <b-nav-item-dropdown text="[传图后台]" right>
              <b-dropdown-item disabled>资料查询</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-ftp-query">[传图后台查询]</router-link></b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item disabled>上传相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-ftp-query-and-operate">重新将图片上传到[下游FTP]</router-link></b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item disabled>ftp相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-ftp-replace-save-folder">更换[储存资料夹]</router-link></b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- 共用 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
          <b-nav-text class="color-text">共用</b-nav-text>
          <b-nav-item-dropdown text="[采集后台]" right>
              <b-dropdown-item disabled>资料查询</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-query">[采集后台查询]</router-link></b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item disabled>采集相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-query-and-operate">重新将[图片采集]</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-query-and-operate">永久停止[图片采集]</router-link></b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item disabled>采集路径相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-replace-download-path">更换大量[采集路径]</router-link></b-dropdown-item>
              <b-dropdown-divider v-if="isDevelopmentOrRoot"/>
              <b-dropdown-item v-if="isDevelopmentOrRoot"  disabled>采集任務相关</b-dropdown-item>
              <b-dropdown-item v-if="isDevelopmentOrRoot"  ><router-link to="/crawler-flower">CeleryFlower</router-link></b-dropdown-item>
          </b-nav-item-dropdown>

           <b-nav-item-dropdown text="[人工管理]" right class="custom-dropdown" @click="isDropdownOpen = !isDropdownOpen">
                <b-dropdown-item disabled>更换图片类型</b-dropdown-item>
                <b-dropdown-item ><router-link to="/human-processing-change-image-type-upload">传图后台-宝典</router-link></b-dropdown-item>
                <b-dropdown-item ><router-link to="/human-processing-change-image-type-crawler">采集后台</router-link></b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item ><router-link to="/human-processing-change-lottery">更换乐透</router-link></b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              <em>帐号</em>
            </template>
            <b-dropdown-item v-if="!isAuthenticated"><router-link to="/login">登入</router-link></b-dropdown-item>
            <b-dropdown-item v-if="isAuthenticated"  @click="logout">登出</b-dropdown-item>
<!--            <b-dropdown-item ><router-link to="/i-want-to-register">注册</router-link></b-dropdown-item>-->
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import {LogOutApiMixin} from "@/mixins/api/user/logOut";

export default {
  mixins: [LogOutApiMixin],
  computed: {
    isAuthenticated() {
        return this.$store.state.authenticate.isAuthenticated;
    },
    isDevelopmentOrRoot() {
        // return  this.$store.state.authorization.level === 'root';
        return  process.env.NODE_ENV === 'development' || this.$store.state.authorization.level === 'root';
    },
  },
  methods: {
    async logout() {
      await this.logOutApi()
      this.$store.commit("authenticate/setAuthenticated", false);
      this.$store.commit("authorization/setAuthorization", "");
      this.$router.push('/login'); // 跳转到登录页面
    },
  },
};
</script>
<style scoped>
.color-text {
  color: #fffb00;
}
</style>