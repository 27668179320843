<template>
    <base-table
        :columns="columns"
        :api_path="api_path"
        :table_height="table_height"
        :api_domain="api_domain"
    />
</template>

<script>
import BaseTable from "@/components/BaseTable.vue";

export default {
  components: {
    'base-table':BaseTable,
  },
  data () {
    return {
      api_domain: "upload",
      api_path: "/upload/servers/info",
      table_height: 370,
      columns: [
                {
                  field: 'server',
                  title: '网站',
                },
                {
                  field: 'server_domain',
                  title: '网站网域',
                },
                {
                  field: 'image_processing',
                  title: '图像处理',
                },
                {
                  field: 'cdn_domain',
                  title: '图片CDN加速网域',
                },
      ]
    }
  },
}
</script>

